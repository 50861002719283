*{
  margin: 0;
  padding:0;
  box-sizing: border-box;

}
html,
                body {
                    overflow-x: hidden;
                }

                 :root {
                    --wHeight: 1920;
                    --wWidth: 1080;
                    --primaryFontColor: #A6CFD5;
                    --secondaryFontColor: #BAC2EA;
                    --hoverColor: #8CE2D6;
                    --heroBackgroundColor: #21292A;
                }
body {
                    font-family: GEO, 'Times New Roman', Times, serif;
                    display: flex;
                    margin: 0;
                    box-sizing: border-box;
                    background-image: url(./assets/Back3.png);
                    background-size: 200vw 200vh;
                    background-repeat: repeat-y;
                    flex-direction: column;
                    align-items: center;
                    overflow-x: hidden;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar {
  margin-top: 30px;
  background-color: white;
                    border-radius: 10px 10px 0 0px;
                    width: 95vw;
                    height: 50px;
                    display: grid;
                    grid-template-columns: 1fr 3fr 1fr;
                    grid-row: 1;
                    align-items: center;
                    justify-content: center;
                }
                .newsSlide{
                  display: block;

                }
                .logo {
                    padding-right: 5px;
                    margin: 0;
                    height: 35px;
                }

                .leftlog {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0;
                    margin: 0 0 0 50px;
                    height: 50px;
                }

                .leftlog img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .leftlog li {
                    font-size: 2.2rem;
                }

                .leftlog li {
                    cursor: pointer;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    color: rgb(20, 20, 20);
                }

                ul li {
                    cursor: default;
                    padding: 0;
                    font-size: x-large;
                    margin: 0;
                    padding: 0;
                    display: inline-block;
                    list-style-type: none;
                }

                .links .padd40 {
                    padding-left: 77px;
                }

                .links {
                    display: flex;
                    align-self: center;
                    justify-content: center;
                }

                .hide {
                    opacity: 0;
                }

                .links li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    color: gray;
                }

                .links .active {
                    color: black;
                }

                .links .active:hover {
                    color: var(--hoverColor);
                    cursor: pointer;
                }

                .links .active .hoverstuff {
                    position: relative;
                    top: 22px;
                    right: -62.5px;
                    display: block;
                    color: var(--hoverColor);
                    cursor: pointer;
                    height: 5px;
                    width: 75px;
                    background-color: var(--hoverColor);
                    opacity: 0;
                }

                .links .active:hover .hoverstuff {
                    opacity: 100;
                }
                                    .hero {

                                    background-image: url(./assets/heroBack.png);
                                    width: 95vw;

                                    background-size: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    border-radius: 0 0 10px 10px;
                                    margin-bottom: 1rem;

                                }
                                .heroDis{
                                    overflow-x: hidden;
                                  width: 50vw;
                                }
                                .heroFAQ {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    flex-direction: column;
                                    margin-bottom: 2rem;
                                    width: 95vw;
                                    background-color: #ffffff;
                                    border-radius: 0 0 10px 10px;
                                }

                                .con {
                                    display: flex;
                                    justify-content: flex-start;
                                    flex-direction: column;
                                    width: 80vw;
                                    font-size: 1.3rem;
                                    font-family: "Poppins", Courier, monospace;
                                      margin-bottom: 2rem;
                                }

                                .heroFAQ h1 {
                                    text-align: center;
                                    margin-top: 3rem;
                                    margin-bottom: 0;
                                    font-size: 4rem;
                                    color: rgb(45, 126, 58);
                                }

                                .question {
                                    margin-left: 1rem;
                                    margin-bottom: 1rem;
                                    font-size: 2rem;
                                    font-weight: 600;
                                }

                                .answer {
                                    margin-left: 1rem;
                                    font-size: 1.1rem;
                                    font-weight: 400;
                                }

                                .heroB {
                                    margin-top: 2rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    height: 90vh;
                                    width: 100%;
                                    background-color: #dcfbff;
                                    z-index: -1;
                                    font-size: 5rem;
                                }

                                .heroImg {
                                    display: flex;
                                    justify-content: flex-end;
                                    width: 80%;
                                    height: 100%;
                                    margin: 0;
                                }

                                .heroImgg {
                                  display: flex;
                                    margin-top: 3rem;
                                    width: 80%;
                                    margin-left: 0;
                                }

                                .hero h1 {
                                    font-weight: 400;
                                    font-size: 6rem;
                                    padding: 0;
                                    margin: 14rem 0 0 0;
                                }

                                .hero h2 {
                                  text-align: left;
                                    font-weight: 400;
                                    font-size: 2.5rem;
                                    padding: 0;
                                    margin: 0 0 0 0;
                                }
                                .hero h3 {
                                  text-align: left;
                                  color: white;
                                    font-weight: 400;
                                    font-size: 1.8rem;
                                    padding: 0;
                                    margin: 1rem 0 0 0;
                                }

                                .hero h1,
                                .hero h2,
                                .hero h3 {
                                    color: white;
                                    padding-left: 3rem;
                                }
                                .play {
                    height: 100px;
                    width: 300px;
                    font-family: GEO, 'Times New Roman', Times, serif;
                    font-size: 2rem;
                    border: 0;
                    border-radius: 5px;
                    background-color: rgb(60, 149, 60);
                    margin: 3rem 0 10rem 4rem;
                    color: #dadada;
                    cursor: default;
                }
                /*.play:hover {
                    background-color: rgb(48, 116, 48);
                    color: #f5f3f3;
                }*/

                .newsh1{
                  margin-top: 2rem;
                  font-size: 2rem;
                }

                .blackDiv{
                  border-radius: 10px;
                  background-image: url(./assets/newsb.png);
                  width: 95vw !important;
                  background-size: cover;
                  text-align: center;
                  overflow-x: visible;

                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  margin-bottom: 2rem;
                }
                .blackDiv2{
                  background-image: url(./assets/newsb.png) !important;
                  background-size:auto;
                }
                .blackDiv h1{

                  padding: 25px;
                }
                .card{
                  text-align: left;
                  background-color: #c9f2e6;
                  padding-left: 0px !important;
                  margin: 0rem;
                  border-radius: 10px 10px ;
                  width: 25rem;
                  font-family: "Poppins", Courier, monospace;

                }
                .cardText{
                  font-size: 1rem !important;
                }
                .card img{
                  padding-left: 0 !important;
                  margin: 0;
                  width: 100%;
                  margin-bottom: 10px;

                }
                .card h3{
                  margin-left: 1rem;
                  margin-right: 1rem;
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                }
                .card p{
                  text-align: justify;
                  margin-left: 1rem;
                  margin-right: 1rem;
                  margin-bottom: 3rem;
                  font-size: 1.3rem;
                }
                .cardgrid{
                  display: flex !important;
                  align-items: center !important;
                  justify-content: center !important;
                }
                .midCon{
                  display: flex;
                  justify-content: center;
                }
                .newsgridHome{
                  display: grid;
                  grid-template-columns: 1fr;
                  grid-row-gap: 2rem;
                  grid-column-gap: 2rem;
                  margin-bottom: 2rem;
                  width: 60%;
                  align-self: center;
                  justify-content: center;
                }
                .newCardPage{
                  width: 95vw !important;
                  text-align: center;
                  background: white;
                  overflow-x: hidden;
                  width: 100%;
                  display: flex;
                  align-self: center;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                }
                .CardPost{
                  margin-top: 5rem;
                  text-align: left;
                  margin-bottom: 5rem;
                }
                .CardPost h2{
                  color: green;

                }
                .newsPostPic{
                  align-self: center;
                  height:50%;
                  width: 70%;
                  max-width: 400px;
                  margin: 2rem;
                  border-radius: 5px;
                }
                .newsPostText{
                  margin-top: 2rem;
                }

                .newsPageGrid{
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  grid-template-rows: 1fr 1fr 1fr;
                  grid-row-gap: 2rem;
                  grid-column-gap: 2rem;
                }

                .showCase {
                  font-family: "Poppins", Courier, monospace;
                    margin-top: 0;
                    width: 95vw;
                    min-height: 50vh;
                    display: flex;
                    flex-direction: row;
                    background-color: black;
                    margin-bottom: 1rem;
                    border-radius: 10px 10px 10px 10px;
                }
                .imgContainer{
                  width: 50%;
                  margin-left: 3rem;
                }
                .showCaseImg {
                  margin-top: 2rem;
                    width:98%;
                }

                .showCaseText {
                  font-weight: 300;
                    background-color: black;
                    width: 50%;
                    text-align: justify;
                    margin-right: 2rem;
                    margin-bottom: 3rem;
                }

                .showCaseText h1,
                .showCase p{
                    color: azure;
                    margin-left: 3rem;
                    margin-left: 3rem;
                    margin-right: 3rem;
                }

                .showCaseText h1 {
                  font-size: 2rem;
                  margin-top: 4rem;
                  margin-bottom: 1rem;
                }

                .showCaseText p {
                  font-size: 1rem;
                  margin-bottom: 1.5rem;
                }
                a{
                  text-decoration: none;
                }
                .question {
                    margin-left: 1rem;
                    font-size: 2rem;
                    font-weight: 600;
                }

                .answer {
                    margin-left: 1rem;

                    font-size: 1.1rem;
                    font-weight: 400;
                    text-align: justify;

                }
                button.accordion {
                    font-family: "Poppins", Courier, monospace;
                    font-size: 1.4rem;
                    font-weight: 700;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    width: 100%;
                    text-align: left;
                    background-color: transparent;
                    color: rgb(19, 19, 19);
                    padding: 8px;
                    margin: 4px 0;
                    border-radius: 4px;
                    transition: 0.3s;
                }

                button.accordion:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                }

                button.accordion.active {
                    background-color: #fff;
                    color: #000;
                    border-radius: 4px 4px 0 0;
                    margin: 4px 0 0;

                }

                div.panel {
                    background-color: rgba(255, 255, 255, 0.8);
                    color: #000;
                    border-radius: 0 0 4px 4px;
                    overflow: hidden;
                    opacity: 0;
                    max-height: 0;
                    transition: 0.3s;
                    padding: 0 8px;
                    margin: 0;

                }

                div.panel.show {
                    opacity: 1;
                    max-height: 500px;
                    padding: 8px;
                    margin: 2px 0 4px;
                    margin-left: 1rem;
                    margin-bottom: 1rem;
                    margin-right: 1rem;
                }

                .question div {
                    font-family: "Poppins", sans-serif;
                }
                .UpFoot {
                    padding-bottom: 3rem;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    font-size: 5rem;
                    background-color: rgb(27, 27, 27);
                }
                .techImg{
                  width: 75%;
                }
                .techImgCon{
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }

/* footer */
.footer {
  text-align: left;
    margin-top: 5rem;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.upsFoot h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: rgb(226, 224, 224);
    font-weight: 500;
}

.upsFoot ul {

  display: block;
    color: rgba(211, 211, 211, 0.582);
    margin: 1rem 0 1rem;
    font-size: 1rem;
    list-style-type: none;
    column-count: 1;

}

.upsFoot ul li {
    display: inline-block;
}

.upsFoot li:hover {
    color: rgba(236, 235, 235, 0.911);
}

.upsFoot li {
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.applestoreFoot {
    height: 100px;
}

.storesFoot ul {
    margin-bottom: 0;
}

.socialFoot ul {
    margin-top: 0;

}

.storesFoot li {
    display: inline;
}

.googlestoreFoot {
    height: 65px;
    transform: translateY(-0.7rem);
}

.socialFoot {
    overflow: hidden;
    height: 62px;
    margin-right: 3rem;
    margin-top: 4rem;
}

.socialFoot .fbIcon {
    margin-left: 0;
    padding-left: 0;
}

.socialFoot li {
    display: block;
    margin: 0 0.5rem 0 0.5rem;
}

.socialFoot img {
    height: 50px;
}

.padd2 {
    padding-right: 1rem;
}

.hamb{
  display: none;
}
a {
    color: inherit;
    text-decoration: none;
}

#page-wrap {
  text-align: center;
  width: 100vw;
  overflow: auto;
}
#wrapedpage{
  display: flex;
  align-items: center;
  flex-direction: column;

}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 65px;
  bottom: 40px;
}

.bm-burger-bars {
  background: #04773B;
}

.bm-cross-button {
  height: 30px;
  width: 50px;
  display: block;
  background-color: white;
}

.bm-cross {
  z-index: 5;
  background-color: orange;
}

.bm-menu {
  background: #058E3F;
  padding: 2.5em 1.5em 0;
  font-size: 2.5rem;
  text-align: center;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.1);
}



/* New CARDS */

.Ncard {
  text-align: left;
  width: 25rem;
  border-radius: 20px;
  font-family: "Poppins", Courier, monospace;
}
.NcardImg{
  border-radius: 20px 20px 7px 7px;
  width:100%;
}
 .Ncard__body {
  padding: 1rem 4%;
}

.Ncard--secondary .Ncard__header {
  color: #fff;
}
.postDate{
  position: absolute;
  top: 0px;
  right: 20px;
  background-color:#434343;
  border-radius: 2px;
  display: inline-block;
  color: white;
  padding: 0px 10px 0 10px;
  margin-top: 20px;
  font-size: 1rem;
}
.plan {
  transition: transform 0.2s ease-out;
  margin: 2rem;

}


.Ncard__body .para{

  color: #585858;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}
.Ncard__body{
  background-color: white;
  position: relative;
  text-align: left;
  color: #434343;
  font-weight: 600;
  font-size: 1.3rem;
  padding-top: 1rem;
  border-radius: 20px;
}
.Ncardtitle{
  padding-bottom: 1rem;
}
.para{
  margin-bottom: 2rem;
}
.corner{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 80px 80px;
  border-color: transparent transparent green transparent;
}
.plan:hover {
  transform: scale(1.05);
}

.plan--popular:hover {
  transform: scale(1.15);
}
/* CARDS OVER */

/* Cycle */
.CycleContainer{
  font-family: Poppins;
  color: rgba(68, 68, 68, 0.92);
  width: 95vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0 ;
  background-color: rgba(255, 255, 255, 0.7);
  padding-bottom: 4rem;
  margin-bottom: 1rem;
}
.CycleDyContainer{
  font-family: Poppins;
  color: rgba(68, 68, 68, 0.92);
  width: 95vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0 0 10px 10px;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}
.CycleDis{
  border-radius: 0 10px 10px 10px ;
  width: 40%;
  text-align: justify;

}

.CycleDyDis{
  padding-left: 3rem;
  border-radius: 0 0 10px 10px ;
  width: 40%;
  text-align: justify;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.CycleDis h1, .CycleDis p{
  margin-left: 3rem;
  margin-right: 3rem;
}
.CycleDis h1{
  font-size: 2rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.CycleDis p{
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.CycleDyDis h1{
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;}
.CycleDyDis p{
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.CyclePic{
  height: 49vh;
  width: 60%;
}
.CycleDyPic{
  height: 49vh;
  width: 60%;
}
.picWrapperStatic{
  border-radius: 20px;
  overflow: hidden;
  height: 49vh;
}
.picWrapper{
  overflow: hidden;
  border-radius: 20px;
  height: 49vh;
}

.picWrapper img{
  height: 100%;
}
.picWrapperStatic img{
  height: 100%;
}
.disable{
  display: none;
}
/* Cycle OVER */
/* THREE */
.ThreeContainer{
  font-family: "Poppins", Courier, monospace;
  border-radius: 10px;
  background-color: azure;
  width: 95vw;
  margin-bottom: 1rem;
}
.ThreeContainer h1{
  margin-top: 1rem;
  padding-top: 4rem;
}
.ThreeBCon{
  margin-top: 5rem;
  margin-bottom: 1rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/*THREE OVER */
/* WHY */
.whyContainer{
  color: rgba(58, 58, 58, 0.92);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: gray;
  border-radius: 20px;

}
.whyContainer img{
  margin-top: 1rem;
  width: 85%;
}
.whyContainer h2{
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 1.2rem;
}
/* WHY OVER */

/* WHY ANIMATION */
.flip-card {
  margin-bottom: 2rem;
  width: 500px;
  height: 500px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: black;
  color: black;
}

.flip-card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: black;
  transform: rotateY(180deg);
}
/* WHY ANIMATION OVER */

/* CYLCE BUTTON */
.custom-btn {
  margin-top: 0rem;
  width: 250px;
  height: 40px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}
.btn-5 {
  background: darkgreen;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-5:hover {
  background: transparent;
  color: #000;
   box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
}
.btn-5:before,
.btn-5:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: #000;
  transition:400ms ease all;
}
.btn-5:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
.btn-5:hover:before,
.btn-5:hover:after{
  width:100%;
  transition:800ms ease all;
}
.btn-5:disabled {
  background: gray;
  color: #fff;
  line-height: 42px;
  padding: 0;
  border: none;
}


/* CYCLE BUTTON OVER */

/* DIS */
.dis{
  background-image: url(./assets/dis.png);
  font-size: 1.3rem;
  font-family: 'Poppins', sans-serif;
  color: azure;
  width: 95vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-size: 100%;
  border-radius: 10px;
  margin: 0;
  margin-bottom: 1rem;
  padding: 2rem;

}
.dis h2{
  margin-bottom: 1rem;
}
/* DIS OVER */

.walletImage {
    width: 50%;
    border-radius: 5%;
    border: 5px solid #555;
}

@media screen and (max-width: 1600px){
  .hero{
    background-size: cover;
  }
  .flip-card {
    width: 400px;
    height: 400px;
  }

}
@media screen and (max-width: 1500px){
  .newsPageGrid{

    grid-template-columns: 1fr 1fr ;
    grid-template-rows: 1fr 1fr ;
    margin-bottom: 2rem;
  }
  .newsgridHome{
    grid-template-columns: 1fr;
  }
  .leftlinks {
    width: 280px;
  }
  .picWrapper img{
    height: 400px;
  }
}

@media screen and (max-width: 1350px) {
    .hero h1 {
        font-weight: 400;
        font-size: 4rem;
        padding: 0;
        margin: 10rem 0 0 3rem;
    }
    .hero h2 {
        font-weight: 400;
        font-size: 1.7rem;
        padding: 0;
        margin: 0 0 0 3rem;
    }
    .play {
        margin-left: 8rem;
    }
    .showCase p {
        font-size: 1rem;
    }

}
@media screen and (max-width: 1300px){
  .flip-card {
    width: 350px;
    height: 350px;
  }
  .CycleContainer{
    height: auto;
    flex-direction: column;
  }
  .CycleDyContainer{
    height: auto;
    flex-direction: column;
  }
  .CyclePic{
    margin-top: 2rem;
    width: 100%;
    height: 100%;

  }
  .CycleDyPic{
    width: 100%;
      height: 100%;
      margin-bottom: 2rem;

  }
  .CycleDyDis{
    width: 90%;
  }
  .CycleDis p{
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .CycleDis h1{
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .CycleDyDis {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .CycleDis{
    width: 90%;
  }
  .picWrapperStatic{
    width: 100%;
      height: 50vw;
  }
  .picWrapper{
    margin-bottom: 2rem;
    height: 50vw;
    width: 100%;
  }

  .picWrapper img{
    width: 100%;
    height: auto;
  }
  .picWrapperStatic img{

    width: 100%;
    height: auto;
  }
  .ThreeBCon{
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
    .blackDiv{
      display: none;
    }
    .blackDiv2{
      display:grid !important;
    }
    .hero h1 {
        font-weight: 400;
        font-size: 4rem;
        padding: 0;
        margin: 6rem 0 0 3rem;
    }
    .hero h2 {
        font-weight: 400;
        font-size: 1.7rem;
        padding: 0;
        margin: 0 0 0 3rem;
    }
    .play {
        margin-left: 6rem;
        margin-bottom: 2rem;
    }
    .showCase h1 {
        margin-top: 3rem;
    }
    .des h2{
      padding-top:1.3rem;
    }
    .walletImage {
        width: 70%;
    }


}
@media screen and (max-width:1050px) {
  .play{
    margin-left: 4rem;
  }
.newsPageGrid{

  grid-template-columns: 1fr ;
  grid-template-rows: 1fr 1fr ;
}
}
@media screen and (max-width:1000px) {
  .newsPostPic{
    width: 80%;
  }
    .heroImg {
        display: flex;
        justify-content: flex-end;
        width: 60%;
        height: 100%;
        margin: 0;
    }
    .walletImage {
        width: 90%;
    }

}
@media screen and (max-width:950px) {
    .showCase p {
        margin-top: 1rem;
    }
    .con {
        width: 80vw;
    }
    .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      right: 35px;
      top: 40px;
    }
    .des h2{
      display: block;
      font-size: 1.3rem;
      height: 100%;
    }
    .des h1, .des h2{
      padding-top: 0.6rem;
      margin-bottom: 0.3rem;
    }

    .leftlinks{
      display: none;
    }

}

@media only screen and (max-width: 800px) {
  .techImg{
    width: 90%;
  }
  .dis{
    font-size: 1rem;
  }
  .newsPageGrid{

    grid-template-columns: 1fr;

  }
  .hero{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heroDis{
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .heroDis h1, .heroDis h2{
    margin: 0;
    margin-bottom: 1rem;
  }
  .heroDis h1{
    font-size: 5rem;
    margin-top: 2rem;
  }
  .heroDis h2{
    font-size: 2rem;
  }
  .play{
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
  }
  .play {
      height: 70px;
      width: 190px;
      font-size: 1.5rem;
      align-self: center;
  }
    .links {
        display: none;
    }
    .heroImg {
        display: none;
    }

    .imgContainer{
    width: 70%;
    margin-left: 0rem;
  }
    .showCase {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .showCase h1 {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 2rem;
    }
    .showCase p {
      text-align: justify;
        font-size: 1rem;
        margin-left: 3rem;
        margin-right: 1rem;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
    }
    .showCase h1 {
        margin-left: 0;
        margin-right: 0;
    }
    .showCaseImg {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .navbar {
        display: flex;
        justify-content: space-between;
    }
    .burger {
        margin-right: 2rem;
    }
    .showCaseText{
      width: 90%;
      text-align: center;
      padding-bottom: 2rem;
    }
}

@media screen and (max-width: 700px) {
  .newsPostPic{
    width: 90%;
  }
    .newsSlide{
      display: none;
    }
    .hero {
        align-items: center;
        justify-content: center;
    }
    .hero h1 {
        margin: 1rem 0 0 0;
        text-align: center;
    }
    .hero h2 {
        margin: 1rem 0 0 0;
        text-align: center;
    }
    .play {
        align-self: center;
    }
    .des h2 {
        margin: inherit 1rem inherit 1rem;
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }
    .des h1 {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 2rem;
    }
    .showCase {
        justify-content: center;
    }
    .showCase p {
        margin: inherit 1rem inherit 1rem;
        margin-right: 0rem;
        margin-left: 2rem;
        font-size: 1rem;
    }
    .showCase h1 {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 2rem;
    }
    .showCaseImg{
      margin-top: 2rem;
      margin-left: 0rem;
      margin-right: 0rem;
    }

    .footer {
        margin-top: 1rem;
        margin-bottom: 1rem;
        flex-direction: column;
        display: block;
        font-size: 2rem;
    }
    .upsFoot {
        margin-top: 0rem;
        padding-top: 0;
        margin-bottom: 0rem;
    }

    ul li {
        font-size: 1rem;
    }
    .leftlog{
      margin-left: 10px
    }
}

@media only screen and (max-width: 650px) {
    .links {
        display: none;
    }

    .navbar {
        display: grid;
        grid-row: 1;
        grid-template-columns: 3fr;
        align-items: center;
        justify-content: center;
    }
    .con {
        width: 90vw;
        font-size: 1rem;
    }

    button.accordion {
        font-size: 1rem;
    }
    .answer {
        font-size: 0.8rem;
    }
    .heroFAQ h1 {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 600px) {
  .heroDis h1{
    font-size: 4rem;
    margin-top: 3rem;
  }
  .heroDis h2{
    font-size: 2rem;
  }
    .play {
        height: 50px;
        width: 150px;
        font-size: 1rem;
        align-self: center;
    }
    .showCaseImg {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .showCaseText {
        margin-right: 0rem;
        margin-bottom: 0rem;
    }

    .des h1{
      margin-bottom: 0.3rem;
      font-size: 1.8rem;
    }
    .des h2{
      font-size: 0.8rem;
    }
}

@media only screen and (max-width: 450px) {
  .heroDis{
    width: 100%;
  }
    .hero {
        background-size: cover;
    }
    .hero h2 {
        margin-top: 0.5rem;
    }
    .play {
        margin-top: 0.5rem;
    }
    .des {

        background-size: cover;
    }
    .des h2 {
        font-size: 0.75rem;
        margin-top: 0;
        padding-top: 0.3rem;
        margin-right: 1rem;
        margin-left: 1rem;
        padding:
    }
    .des h1 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }
    .showCase p {
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: 0.75rem;
    }
    .showCase h1 {
        margin-left: 0;
        margin-right: 0;
    }
    .showCaseImg {
        margin-left: 0rem;
        margin-right: 0rem;
    }
}

@media only screen and (min-width: 1800px) {
  .CycleDyDis{
    margin-left: 8rem;
  }
  .CycleDis{
    margin-right: 8rem;
  }
    .hero h1 {
        margin-top: 15rem;
        font-size: 9rem;
    }
    .play {
        height: 100px;
        width: 300px;
    }
    .des h2 {
        font-size: 2rem;
        margin-right: 4rem;
        margin-left: 4rem;
    }
    .des h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
        margin-right: 4rem;
        margin-left: 4rem;
    }
    .showCase h1 {
        font-size: 3rem;
        margin-bottom: 2rem;
        margin-right: 4rem;
        margin-left: 4rem;
    }
    .showCase p {
        font-size: 1.2rem;
        margin-right: 4rem;
        margin-left: 4rem;
    }
    .des {
        display: flex;
        justify-content: center;
        flex-direction: column;

    }
}

@media only screen and (min-width: 2200px) {
    .hero h1 {
        margin-top: 18rem;
        margin-left: 6rem;
        font-size: 9rem;
    }
    .hero h2 {
        margin-left: 6rem;
        font-size: 4rem;
    }
    .play {
        margin-left: 20rem;
        height: 125px;
        width: 450px;
    }
}

.status-light {
    border-radius: 9999px;
    height: 80%;
    width: 80%;
    margin-right: 0.5rem;
}

.status-light.received {
    background-color: gray;
}

.status-light.node {
    background-color: yellow;
}

.status-light.confirmed {
    background-color: #16a34a;
}

/* ***************** */
.responsive-table{
  margin-top: 3rem;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
}
  .table-row, .table-header{

    border-radius: 3px;
    padding: 25px 30px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #7cd2e3;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-header-add {
    background-color: #7cd2e3;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
  }
  .col-1 {
    flex-basis: 5%;
  }
  .col-2 {
    flex-basis: 25%;
  }
  .col-3 {
    flex-basis: 20%;
  }
  .col-4 {
    flex-basis: 20%;
  }
  .col-5 {
    flex-basis: 30%;
  }
.divload{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadtrans{
  width: 300px;
  height: 60px;
  font-size: 1.5rem;
}
  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {

      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #7cd2e3;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }

.sectionTable{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.wide30{
  width: 28%;
}
.wide70{
  width: 68%;
}
